const INFO = {
	main: {
		title: "Furkan Meclis",
		name: "Furkan Meclis",
		email: "furkanmeclis@icloud.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/furkan_meclis",
		github: "https://github.com/furkanmeclis",
		instagram: "https://instagram.com/furkan.meclis",
	},

	homepage: {
		title: "Full-stack web developer, and amateur astronaut.",
		description:
			"I am a full-stack developer with expertise in Node.js, PHP, React, Laravel, HTML, and CSS. I have experience in building scalable, secure, and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and am open to learning new skills. Creating high-quality code that adheres to industry standards and best practices is my passion. I am always on the lookout for new challenges and opportunities for growth.",
	},

	about: {
		title: "I'm a full-stack developer based in Turkey.",
		description:
			"I have been continuously improving myself in the software industry for 8 years. Concurrently, I am pursuing my education in the Electrical and Electronics Engineering department at Niğde Ömer Halisdemir University. My primary focus lies in HTML, CSS, PHP, JS, and their respective libraries. I have a passion for problem-solving and take pride in providing creative, personalized solutions that can potentially save you from significant expenses.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "CKYMOTO THEME",
			description:
				"Special Theme Work for Ideasoft Software, an E-Commerce Intermediary",
			logo: "https://www.ckymoto.com/favicon.ico",
			linkText: "View Work",
			link: "https://ckymoto.com",
		},

		{
			title: "CKYMOTO SALESMAN PANEL",
			description:
				"CKYMOTO Sales Representative & Marketer Panel",
			logo: "https://www.ckymoto.com/favicon.ico",
			linkText: "View Panel",
			link: "https://pazarlamaci.ckymotoservice.com",
		},

		{
			title: "CKYMOTO PACKAGE TRACKING",
			description:
				"CKYMOTO package tracking and management panel",
			logo: "https://www.ckymoto.com/favicon.ico",
			linkText: "View Panel",
			link: "https://paket.ckymotoservice.com",
		},
		{
			title: "ZEONMOTO",
			description:
				"ZEONMOTO catalog and contact website.",
			logo: "https://zeonmoto.com/static/media/zeon.ddc6814d3b7a9288415b.png",
			linkText: "View Website",
			link: "https://zeonmoto.com",
		},{
			title: "ZEONMOTO PANEL",
			description:
				"ZEONMOTO product managament panel.",
			logo: "https://zeonmoto.com/static/media/zeon.ddc6814d3b7a9288415b.png",
			linkText: "View Panel",
			link: "https://panel.zeonmoto.com",
		},

		{
			title: "ALHANGRUP",
			description:
				"Promotion of Alhangrup sectors and exhibition of products project",
			logo: "https://alhangrup.com.tr/wp-content/plugins/furkanmeclis-alhangrup/assets/cropped.png",
			linkText: "View Website",
			link: "https://alhangrup.com.tr",
		},
	],
};

export default INFO;
